import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function Navbar() {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  // Função para trocar de idioma
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    console.log(`Idioma alterado para: ${lang}`);
  };

  // Função para abrir/fechar o menu de hambúrguer
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-dea-beige shadow-sm sticky-top">
      <div className="container mx-auto flex justify-between items-center p-4">
        {/* Logo */}
        <a className="navbar-brand" href="https://www.deaservicecomponentes.com.br">
          <img src="/images/logo.svg" alt="DEA Service Componentes" height="40" />
        </a>

        {/* Botão de menu de hambúrguer para telas pequenas */}
        <div className="lg:hidden">
          <button
            onClick={toggleMenu}
            className="text-gray-800 focus:outline-none"
          >
            {/* Ícone de hambúrguer */}
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'} />
            </svg>
          </button>
        </div>

        {/* Links do menu (colapsável em telas pequenas) */}
        <div className={`lg:flex ${isOpen ? 'block' : 'hidden'} w-full lg:w-auto`}>
          <ul className="flex flex-col lg:flex-row lg:space-x-4 lg:space-y-0 space-y-2 mt-4 lg:mt-0">
            <li className="nav-item">
              <a className="nav-link text-dark" href="#home">{t('navbar.home')}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-dark" href="#about">{t('navbar.about')}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-dark" href="#products">{t('navbar.products')}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-dark" href="#services">{t('navbar.services')}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-dark" href="#contact">{t('navbar.contact')}</a>
            </li>
          </ul>
        </div>

        {/* Alternância de Idiomas */}
        <div className="ml-4 flex space-x-2">
          <button
            onClick={() => changeLanguage('en')}
            className="px-4 py-2 bg-dea-green text-white font-medium rounded hover:bg-dea-green-dark"
          >
            EN
          </button>
          <button
            onClick={() => changeLanguage('pt')}
            className="px-4 py-2 bg-dea-green text-white font-medium rounded hover:bg-dea-green-dark"
          >
            PT
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
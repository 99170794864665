import React from 'react';
import { useTranslation } from 'react-i18next';

function Hero() {
  const { t } = useTranslation();  // Usa o hook para acessar as traduções

  return (
    <section
      id="home"
      className="hero bg-cover bg-center h-screen text-white flex items-center justify-center"
      style={{ backgroundImage: "url('/images/hero-image.jpg')" }}
    >
      <div className="text-center">
        {/* Substitua o texto estático pela chave de tradução */}
        <h1 className="text-5xl font-bold">{t('hero.title')}</h1>
        
        {/* Substitua a descrição estática pela chave de tradução */}
        <p className="mt-4 text-xl">{t('hero.description')}</p>
        
        {/* Substitua o texto do botão pela chave de tradução */}
        <a
          href="#about"
          className="mt-6 inline-block px-8 py-3 bg-dea-green hover:bg-dea-green-dark text-white font-medium rounded"
        >
          {t('hero.cta')}  {/* Usando a chave 'cta' do JSON */}
        </a>
      </div>
    </section>
  );
}

export default Hero;
import React from 'react';
import { useTranslation } from 'react-i18next';

function AboutUs() {

  const { t } = useTranslation();  // Usa o hook para acessar as traduções

  return (
    <section id="about" className="py-16 bg-gray-200">
      <div className="container mx-auto px-6">
        <div className="lg:flex lg:items-center space-y-6 lg:space-y-0">
          {/* Texto na esquerda em telas grandes, empilhado em mobile */}
          <div className="lg:w-1/2 text-center lg:text-left">
            {/* Substitua o título estático pela chave de tradução */}
            <h2 className="text-3xl font-bold mb-4">{t('about.title')}</h2>

            {/* Substitua a descrição estática pela chave de tradução */}
            <p className="text-lg mb-6">
              {t('about.description')}
            </p>
          </div>

          <div className="lg:w-1/2">
            <img src="/images/about-image.jpg" alt="About Us" className="rounded shadow-lg" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import emailjs from 'emailjs-com';

function ContactForm() {
  const { t } = useTranslation();  // Usa o hook para acessar as traduções

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = 'service_8jdml36';
    const templateId = 'template_u2mq5yr';
    const userId = '-ph3JhUVbPNLQM2mI';

    emailjs.send(serviceId, templateId, formData, userId)
      .then((response) => {
        alert(t('contact.success'));  // Mensagem de sucesso traduzida
        setFormData({ name: '', email: '', subject: '', message: '' });
      })
      .catch((error) => {
        console.error('Erro ao enviar o email:', error);
        alert(t('contact.error'));  // Mensagem de erro traduzida
      });
  };

  return (
    <section id="contact" className="py-16 bg-gray-200">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-8">{t('contact.title')}</h2>  {/* Título traduzido */}
        <form className="max-w-lg mx-auto" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="name">
              {t('contact.name')}  {/* Tradução para "Nome" */}
            </label>
            <input
              type="text"
              id="name"
              className="w-full px-4 py-2 border border-gray-300 rounded"
              placeholder={t('contact.namePlaceholder')}
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="email">
              {t('contact.email')}  {/* Tradução para "Email" */}
            </label>
            <input
              type="email"
              id="email"
              className="w-full px-4 py-2 border border-gray-300 rounded"
              placeholder={t('contact.emailPlaceholder')}
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="subject">
              {t('contact.subject')}  {/* Tradução para "Assunto" */}
            </label>
            <input
              type="text"
              id="subject"
              className="w-full px-4 py-2 border border-gray-300 rounded"
              placeholder={t('contact.subjectPlaceholder')}
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="message">
              {t('contact.message')}  {/* Tradução para "Mensagem" */}
            </label>
            <textarea
              id="message"
              className="w-full px-4 py-2 border border-gray-300 rounded"
              rows="5"
              placeholder={t('contact.messagePlaceholder')}
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="px-6 py-2 bg-dea-green hover:bg-dea-green-dark text-white font-medium rounded"
            >
              {t('contact.submit')}  {/* Texto do botão traduzido */}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default ContactForm;
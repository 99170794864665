import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import AboutUs from './components/AboutUs';
import Products from './components/Products';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import './index.css'; // Import Tailwind styles
import './i18n';

function App() {
  return (
    <div className='bg-dea-beige min-h-screen'>
      <Navbar />
      <Hero />
      <AboutUs />
      <Products />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default App;
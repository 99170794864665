import React from 'react';
import { useTranslation } from 'react-i18next';

function Products() {

  const { t } = useTranslation();  // Usa o hook para acessar as traduções
  
  return (
    <section id="products" className="py-16">
      <div className="container mx-auto px-6">
        {/* Substitua o título estático pela chave de tradução */}
        <h2 className="text-3xl font-bold text-center mb-8">{t('products.title')}</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          
          {/* Produto 1 */}
          <div className="card shadow-lg hover:shadow-xl transition-shadow duration-300">
            <img src="/images/product-1.jpg" alt={t('products.sealers.name')} className="w-full h-64 object-cover" />
            <div className="p-4">
              <h3 className="font-bold text-lg">{t('products.sealers.name')}</h3>
              <p className="text-sm mt-2">{t('products.sealers.description')}</p>
            </div>
          </div>

          {/* Produto 2 */}
          <div className="card shadow-lg hover:shadow-xl transition-shadow duration-300">
            <img src="/images/product-2.jpg" alt={t('products.nozzle.name')} className="w-full h-64 object-cover" />
            <div className="p-4">
              <h3 className="font-bold text-lg">{t('products.nozzle.name')}</h3>
              <p className="text-sm mt-2">{t('products.nozzle.description')}</p>
            </div>
          </div>

          {/* Produto 3 */}
          <div className="card shadow-lg hover:shadow-xl transition-shadow duration-300">
            <img src="/images/product-3.jpg" alt={t('products.pumps.name')} className="w-full h-64 object-cover" />
            <div className="p-4">
              <h3 className="font-bold text-lg">{t('products.pumps.name')}</h3>
              <p className="text-sm mt-2">{t('products.pumps.description')}</p>
            </div>
          </div>
          
        </div>
      </div>
    </section>
  );
}

export default Products;
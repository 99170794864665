import React from 'react';

function Footer() {
  return (
    <footer className="bg-dea-green text-white py-8">
      <div className="container mx-auto px-6">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <p className="text-sm">
            &copy; 2024 DEA Service Componentes. All rights reserved.
          </p>
          <div className="flex space-x-4">
            <a href="https://facebook.com" target="_blank" rel="noreferrer">
              <img
                src="https://cdn.jsdelivr.net/npm/bootstrap-icons/icons/facebook.svg"
                alt="Facebook"
                height="20"
                className="filter invert"
              />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noreferrer">
              <img
                src="https://cdn.jsdelivr.net/npm/bootstrap-icons/icons/instagram.svg"
                alt="Instagram"
                height="20"
                className="filter invert"
              />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noreferrer">
              <img
                src="https://cdn.jsdelivr.net/npm/bootstrap-icons/icons/linkedin.svg"
                alt="LinkedIn"
                height="20"
                className="filter invert"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;